<template>
  <div class="mycourses online_courses_info_exam">
    <section class="course_title">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.webp" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ video.name }}</h3>
        <div class="course_info">
          <p class="episode"><small>NO.{{ unitNum }}</small></p>
          <p class="course_name">{{ course.name }}</p>
          <p class="period">觀看/測驗期限: {{ computedExpiryTime }}</p>
        </div>
      </div>
    </section>

    <div class="course_description result_description">
      <div class="w1100">
        <template v-if="video.quest_count - wrongNum >= video.answer_count">
          <p class="ls2">恭喜您通過測驗！</p>
        </template>
        <template v-else>
          <p class="ls2">您未通過測驗，請返回觀看影片或重新測驗！<br>
            <span class="txt-bold ls2 h4">
              共{{ questionNum }}題，
              <span class="txt-red">錯誤：{{ wrongNum }}題</span>
            </span>
          </p>
          <div class="btn_wrap without_heart_btn active">
            <router-link
              :to="`/register/online/mycourse/video?
                course_id=${courseID}&unit=${unitNum}&video_id=${videoID}`"
              class="btn"
            >
              返回觀看影片
            </router-link>
            <router-link
              :to="`/register/online/mycourse/exam?
                course_id=${courseID}&unit=${unitNum}&video_id=${videoID}`"
              class="btn"
            >
              重新測驗
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="quiz-area">
      <div class="w1000">
        <div id="quiz" class="quiz">
          <ol class="quiz-list">
            <QuizList
              v-for="quiz in video.course_video_quests"
              :key="quiz.uuid"
              :quiz="quiz"
              :answerResult="answerPerQuiz(quiz.uuid)"
            />
          </ol>
          <div class="btn_wrap">
            <router-link :to="`/register/online/mycourse/info?course_id=${courseID}`" class="btn">
              返回課程
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import QuizList from '@/components/QuizList.vue';
import { coursesView, courseAnswer } from '@/lib/http';
import { expiryDate } from '@/lib/public';
import { mapMutations } from 'vuex';

export default {
  name: 'RegisterOnlineMycourseResult',
  data() {
    return {
      courseID: '',
      videoID: '',
      unitNum: '',
      course: {
        // name: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員教育訓練',
        // unitNumber: '01',
        // unitName: '安寧療護臨床決策的倫理與困境',
        // expiration: '2020/12/31 11:15',
        // quizList: [
        //   {
        //     id: 1,
        //     question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
        //     optionList: [
        //       {
        //         id: 1,
        //         text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
        //       },
        //       {
        //         id: 2,
        //         text: '減少併發症',
        //       },
        //       {
        //         id: 3,
        //         text: '減少醫療開銷',
        //       },
        //       {
        //         id: 4,
        //         text: '以上皆是',
        //       },
        //     ],
        //     chooseAnswer: '1',
        //     correctAnswer: '3',
        //   },
        //   {
        //     id: 2,
        //     question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
        //     optionList: [
        //       {
        //         id: 1,
        //         text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
        //       },
        //       {
        //         id: 2,
        //         text: '減少併發症',
        //       },
        //       {
        //         id: 3,
        //         text: '減少醫療開銷',
        //       },
        //       {
        //         id: 4,
        //         text: '以上皆是',
        //       },
        //     ],
        //     chooseAnswer: '2',
        //     correctAnswer: '2',
        //   },
        //   {
        //     id: 3,
        //     question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
        //     optionList: [
        //       {
        //         id: 1,
        //         text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
        //       },
        //       {
        //         id: 2,
        //         text: '減少併發症',
        //       },
        //       {
        //         id: 3,
        //         text: '減少醫療開銷',
        //       },
        //       {
        //         id: 4,
        //         text: '以上皆是',
        //       },
        //     ],
        //     chooseAnswer: '3',
        //     correctAnswer: '4',
        //   },
        //   {
        //     id: 4,
        //     question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
        //     optionList: [
        //       {
        //         id: 1,
        //         text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
        //       },
        //       {
        //         id: 2,
        //         text: '減少併發症',
        //       },
        //       {
        //         id: 3,
        //         text: '減少醫療開銷',
        //       },
        //       {
        //         id: 4,
        //         text: '以上皆是',
        //       },
        //     ],
        //     chooseAnswer: '4',
        //     correctAnswer: '4',
        //   },
        //   {
        //     id: 5,
        //     question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
        //     optionList: [
        //       {
        //         id: 1,
        //         text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
        //       },
        //       {
        //         id: 2,
        //         text: '減少併發症',
        //       },
        //       {
        //         id: 3,
        //         text: '減少醫療開銷',
        //       },
        //       {
        //         id: 4,
        //         text: '以上皆是',
        //       },
        //     ],
        //     chooseAnswer: '4',
        //     correctAnswer: '1',
        //   },
        // ],
      },
      /**
       * Axios Response Data
       */
      video: {},
      answerData: '',
      answerResult: '',
    };
  },
  created() {
    this.courseID = this.$route.query.course_id;
    this.videoID = this.$route.query.video_id;
    this.unitNum = this.$route.query.unit;
    this.answerData = this.$cookies.get('answerData');
    coursesView({
      uuid: this.courseID,
    }).then((result) => {
      this.course = result.data;

      /** 已購買 */
      if (this.course.bought_completed === false
        || this.course.bought_completed === true
      ) {
        /** 有限期 */
        if (this.course.expiry_time && this.course.expiry_time > 0) {
          /** 未過期 */
          if (moment(this.course.bought_expiry_time).isAfter()) {
            this.course.course_videos.forEach((video) => {
              if (video.uuid === this.videoID) {
                this.video = video;
              }
            });
          /** 已過期 */
          } else {
            this.assignUrlRedirect('/register/online/index');
            this.updateModalInfoContent('課程已過期，請返回課程總覽');
            this.toggleModalInfo(true);
          }
        /** 無限期 */
        } else {
          this.course.course_videos.forEach((video) => {
            if (video.uuid === this.videoID) {
              this.video = video;
            }
          });
        }
      /** 未登入、未購買 */
      } else {
        this.assignUrlRedirect('/register/online/index');
        this.updateModalInfoContent('您尚未登入或購買該課程，請返回課程總覽');
        this.toggleModalInfo(true);
      }
    });
    courseAnswer(this.answerData).then((result) => {
      this.answerResult = result;
    });
  },
  computed: {
    computedExpiryTime() {
      /** 無期限 */
      if (!this.course.expiry_time) {
        return '無限制';
      }
      /** 課程已購買 */
      if (this.course.bought_completed === false || this.course.bought_completed === true) {
        return this.course.bought_expiry_time;
      }
      /** 課程未購買 */
      return `${this.course.expiry_days}天`;
    },
    questionNum() {
      let val = '';
      if (this.answerResult) {
        val = this.answerResult.length;
      }
      return val;
    },
    wrongNum() {
      const wrongAnswer = [];
      if (this.answerResult) {
        this.answerResult.forEach((answer) => {
          if (answer.completed * 1 === 0) {
            wrongAnswer.push(answer.uuid);
          }
        });
      }
      return wrongAnswer.length;
    },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'assignUrlRedirect',
    ]),
    answerPerQuiz(uuid) {
      let target = {};
      if (this.answerResult) {
        this.answerResult.forEach((answer) => {
          if (answer.uuid === uuid) {
            target = answer;
          }
        });
      }
      return target;
    },
    expiryDate,
  },
  components: {
    QuizList,
  },
};
</script>
