<template>
  <li class="quiz-wrapper">
    <!-- 測驗題目 -->
    <h4>
      {{ quiz.title }}
      <span
        class="wrong_message txt-red"
        :class="{ active: errMsgActive }"
      >(此題答錯)</span>
    </h4>

    <!-- 測驗選項 -->
    <template v-if="quiz.type * 1 === 1">
      <div
        class="option-wrapper"
        v-for="(option, index) in quiz.quests"
        :key="index"
      >
        <input type="radio"
          :id="`${quiz.uuid}_${index + 1}`"
          :value="index + 1"
          v-model="answer"
          @change="chooseAnswer"
          :disabled="isDisabled"
        >
        <label class="p" :for="`${quiz.uuid}_${index + 1}`">
          {{ index + 1 }}) {{ option.answer }}
        </label>
      </div>
    </template>
    <template v-else>
      <div
        class="option-wrapper"
      >
        <input type="radio"
          :id="`${quiz.uuid}_1`"
          :value="1"
          v-model="answer"
          @change="chooseAnswer"
          :disabled="isDisabled"
        >
        <label class="p" :for="`${quiz.uuid}_1`">
          1) {{ quiz.quests[0].answer }}
        </label>
      </div>
      <div
        class="option-wrapper"
      >
        <input type="radio"
          :id="`${quiz.uuid}_2`"
          :value="2"
          v-model="answer"
          @change="chooseAnswer"
          :disabled="isDisabled"
        >
        <label class="p" :for="`${quiz.uuid}_2`">
          2) {{ quiz.quests[1].answer }}
        </label>
      </div>
    </template>
  </li>
</template>

<script>
export default {
  name: 'QuizList',
  props: [
    'quiz',
    'updateAnswer',
    'answerResult',
  ],
  data() {
    return {
      answer: '',
    };
  },
  updated() {
    if (this.answerResult) {
      if (this.answerResult.completed * 1 === 1) {
        this.answer = this.answerResult.answer;
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.answerResult) {
        return true;
      }
      return false;
    },
    errMsgActive() {
      if (this.answerResult) {
        if (this.answerResult.completed * 1 === 0) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    chooseAnswer() {
      this.$emit('updateAnswer', {
        uuid: this.quiz.uuid,
        answer: this.answer,
      });
    },
  },
};
</script>
